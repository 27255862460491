import React from 'react';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import { Helmet } from 'react-helmet';
import 'stylesheet/info.scss';
import { ArrowAbout } from 'components/svg';
import { LinkExternalTag } from 'components/globaltags';
import { GlobalContext } from '../context/GlobalContext';
// import { ImageLoadClass } from 'utils/loader';
import { checkFile } from 'utils/common';
import ImageParser from 'components/imageParser';

export default class Info extends React.Component {
  render() {
    const post = this.props.data.content;
    return (
      <GlobalContext.Consumer>
        {(context) => {
          let seo_image = '';
          if (post.frontmatter.seo.seo_image) {
            seo_image = `${context.weburl}${checkFile(
              post.frontmatter.seo.seo_image.publicURL
            )}`;
          }
          const seo = {
            desc: post.frontmatter.seo.seo_shortdesc,
            keywords: post.frontmatter.seo.seo_keywords,
            image: seo_image,
          };
          const translation = post.frontmatter.translation;
          let disablepress = false;
          if (
            translation.selectedpress_content.length === 1 &&
            translation.selectedpress_content[0].content === ''
          )
            disablepress = true;
          return (
            <Layout
              titleText={post.frontmatter.title}
              mainID={'Info'}
              location={this.props.location}
            >
              {/* SEO */}
              <Helmet>
                <meta name='description' content={seo.desc} />
                <meta name='image' content={seo.image} />
                <meta name='keywords' content={seo.keywords} />
                {seo.desc && (
                  <meta property='og:description' content={seo.desc} />
                )}
                {seo.image && <meta property='og:image' content={seo.image} />}
                <meta name='twitter:card' content='summary_large_image' />
                {seo.desc && (
                  <meta name='twitter:description' content={seo.desc} />
                )}
                {seo.image && <meta name='twitter:image' content={seo.image} />}
              </Helmet>
              {/* SEO */}
              <div className='info_wrapper'>
                <div>
                  {translation.repeating.map((repeat, index) => (
                    <div key={index}>
                      <h1>
                        {repeat.title} <ArrowAbout />
                      </h1>
                      <p>{repeat.content}</p>
                    </div>
                  ))}
                  {disablepress === false && (
                    <div>
                      <h1>
                        {translation.selectedpress} <ArrowAbout />
                      </h1>
                      <ul>
                        {translation.selectedpress_content.map(
                          (press, index) => {
                            if (press.link !== '') {
                              return (
                                <li key={index}>
                                  <LinkExternalTag
                                    href={press.link}
                                    target='_blank'
                                    rel='noopener noreferrer'
                                  >
                                    {press.content}
                                  </LinkExternalTag>
                                </li>
                              );
                            } else {
                              return (
                                <li key={index}>
                                  <span>{press.content}</span>
                                </li>
                              );
                            }
                          }
                        )}
                      </ul>
                    </div>
                  )}
                </div>
                <div>
                  <ImageParser
                    relativepath={post.frontmatter.info_image.publicURL}
                    alt={`${context.webname} Information`}
                    loading='eager'
                    style={{
                      width: '100%',
                      height: '100%',
                      background: '#DDDDDD',
                    }}
                    childImageSharp={
                      post.frontmatter.info_image.childImageSharp
                    }
                  />
                  {/* <img
                    src={checkFile(post.frontmatter.info_image)}
                    alt={`${context.webname} Information`}
                    loading='eager'
                  /> */}
                </div>
              </div>
            </Layout>
          );
        }}
      </GlobalContext.Consumer>
    );
  }
}

export const query = graphql`
  query {
    content: markdownRemark(
      frontmatter: { issetting: { eq: false }, contenttype: { eq: "info" } }
    ) {
      frontmatter {
        title
        info_image {
          publicURL
          childImageSharp {
            gatsbyImageData(
              quality: 100
              placeholder: DOMINANT_COLOR
              layout: FULL_WIDTH
            )
          }
        }
        translation {
          repeating {
            title
            content
          }
          selectedpress
          selectedpress_content {
            content
            link
          }
        }
        seo {
          seo_keywords
          seo_shortdesc
          seo_image {
            publicURL
          }
        }
      }
    }
  }
`;
