import React from 'react';
import { LinkHoverColor } from 'utils/common';
import { Link } from 'gatsby';

export const LinkExternalTag = props => {
  return (
    <a
      href={props.href}
      className={props.className}
      target={props.target}
      rel={props.rel}
      onMouseEnter={LinkHoverColor}
      onMouseLeave={LinkHoverColor}
    >
      {props.children}
    </a>
  );
};

export const LinkTag = props => {
  return (
    <Link
      href={props.href}
      className={props.className}
      onMouseEnter={LinkHoverColor}
      onMouseLeave={LinkHoverColor}
    >
      {props.children}
    </Link>
  );
};

